import $ from 'jquery'

window.addEventListener("DOMContentLoaded", function () {
  console.log("DOMContentLoaded")
  let modal = document.getElementById("modal");
  let btn = document.getElementById("showreel-btn");
  let iframe = document.getElementById("ifr");

  var rexBtn = document.querySelector(".rex-btn");
  var mobileRexBtn = document.querySelector(".mobile-rex-btn");
  var rexModal = document.querySelector(".rex-modal");
  var rexFrame = document.querySelector(".rex-frame");

  let x = document.getElementById("close");
  let menu = document.getElementById("menu");
  let container = document.getElementById("menu-container");

  //OLIKA BILDER PÅ DESKTOP OCH MOBIL//

  let myre = document.getElementById("myre-img");
  let svg2 = document.getElementById("svg2-img");
  let rosteriet = document.getElementById("rost-img");
  let interkakel = document.getElementById("interkakel-img");
  // let kansas = document.getElementById("kansas-img");
  // let villan = document.getElementById("smv-img");
  let kaffekomp = document.getElementById("kaffek-img");
  let sica = document.getElementById("sica-img");
  let tamro = document.getElementById("tamro-img");

  if (btn) {
    btn.addEventListener("click", function () {
      modal.style.display = "block";
      iframe.src = "https://player.vimeo.com/video/546091962";
    });
  }

  if (rexBtn) {
    rexBtn.addEventListener("click", function () {
      rexModal.style.display = "block";
      rexFrame.src = "https://www.youtube.com/embed/5xK36K3DV5Y";
    });
  }

  if (mobileRexBtn) {
    mobileRexBtn.addEventListener("click", function () {
      rexModal.style.display = "block";
      rexFrame.src = "https://www.youtube.com/embed/5xK36K3DV5Y";
    });
  }

  document.addEventListener("click", function (event) {
    if (event.target == modal) {
      modal.style.display = "none";
      iframe.src = "";
    } else if (event.target == rexModal) {
      rexModal.style.display = "none";
      rexFrame.src = "";
    }
  });

  if (x) {
    x.onclick = function () {
      if (menu.style.width === "100%") {
        menu.style.width = 0;
        container.style.width = 0;
        container.style.transition = "width .2s";
        container.style.display = "none";
      }
    };
  }

  if (myre) {
    if (window.innerWidth < 376) {
      myre.src = "/assets/frontpage-img/myresjohus-mobil-min.jpg";
      rosteriet.src = "/assets/frontpage-img/hemmarosteriet-mobil-min.jpg";
      svg2.src = "/assets/frontpage-img/Puffbild-SmV-Rymden.jpg";
      interkakel.src = "/assets/frontpage-img/interkakel_sten.jpg";
      // kansas.src = "/assets/frontpage-img/goodbyekansas-mobil-min.jpg";
      // villan.src = "/assets/frontpage-img/humomhus-mobil-min.jpg";
      kaffekomp.src = "/assets/frontpage-img/kaffekompaniet-mobil-min.jpg";
      sica.src = "/assets/frontpage-img/sicalight-mobil-min.jpg";
      tamro.src = "/assets/frontpage-img/tamro-mobil-min.jpg";
    } else if (window.innerWidth > 376) {
      myre.src = "/assets/frontpage-img/katalog-cover-min.jpg";
      rosteriet.src = "/assets/frontpage-img/alva-fika-min.jpg";
      svg2.src = "/assets/frontpage-img/Puffbild-SmV-Rymden.jpg";
      interkakel.src = "/assets/frontpage-img/interkakel_sten.jpg";
      // kansas.src = "/assets/frontpage-img/goodbye-kansas-min.jpg";
      // villan.src = "/assets/frontpage-img/hum-om-hus-min.jpg";
      kaffekomp.src = "/assets/frontpage-img/kaffekompaniet-branding-min.jpg";
      sica.src = "/assets/frontpage-img/sica-light-min.jpg";
      tamro.src = "/assets/frontpage-img/tamro-branding-min.jpg";
    }
  }

});
window.myMap = function() {
  if (window.google) {
    var office = { lat: 57.7024242049303, lng: 11.958053498676787 };

    var map = new google.maps.Map(document.getElementById("googleMap"), {
      zoom: 16,
      center: office,
      styles: [
        {
          featureType: "administrative.locality",
          elementType: "all",
          stylers: [
            {
              hue: "#2c2e33",
            },
            {
              saturation: 7,
            },
            {
              lightness: 19,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
            {
              saturation: "-3",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#E7522A",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              hue: "#ff0000",
            },
            {
              saturation: "-80",
            },
            {
              lightness: "-1",
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              hue: "#ff0000",
            },
            {
              saturation: -100,
            },
            {
              lightness: 100,
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              lightness: "-53",
            },
          ],
        },
        {
          featureType: "poi.school",
          elementType: "geometry.fill",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#ff8157",
            },
            {
              lightness: "40",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              hue: "#ff6f00",
            },
            {
              saturation: "100",
            },
            {
              lightness: 31,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#E7522A",
            },
            {
              saturation: "0",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              saturation: -93,
            },
            {
              lightness: 31,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text",
          stylers: [
            {
              weight: "4.00",
            },
            {
              saturation: "-91",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: "1",
            },
            {
              lightness: "1",
            },
            {
              gamma: "1.00",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              saturation: "100",
            },
            {
              lightness: "10",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.stroke",
          stylers: [
            {
              lightness: "1",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {
              weight: "10.00",
            },
            {
              visibility: "on",
            },
            {
              lightness: "-2",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "all",
          stylers: [
            {
              saturation: "0",
            },
            {
              lightness: "10",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#f3dbc8",
            },
            {
              saturation: "0",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels",
          stylers: [
            {
              hue: "#bbc0c4",
            },
            {
              saturation: -93,
            },
            {
              lightness: -2,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              hue: "#007fff",
            },
            {
              saturation: "-100",
            },
            {
              lightness: "14",
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              hue: "#e9ebed",
            },
            {
              saturation: 10,
            },
            {
              lightness: 69,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              hue: "#007fff",
            },
            {
              saturation: "-100",
            },
            {
              lightness: "100",
            },
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    var marker = new google.maps.Marker({
      position: office,
      map: map,
    });
  }
}

//MOBILE MENU
function toggleMenu() {
  let menu = document.getElementById("menu");
  let container = document.getElementById("menu-container");

  if (menu.style.width === "100%") {
    menu.style.width = 0;
    container.style.width = 0;
    container.style.transition = "width .2s";
    container.style.display = "none";
  } else {
    menu.style.width = "100%";
    container.style.width = "100%";
    container.style.transition = "width .5s";
    container.style.display = "flex";
  }
}

window.$mcj = $.noConflict(true);

$(document).ready(function () {
  // var startsida_toppfilm = $("#startsida_toppvideo");
  var woman_in_taxi = $("#woman_in_taxi");
  var baby_video = $("#baby_video");
  var waitress_dinner = $("#waitress_dinner");
  var baby_video_ipad = $("#baby_video");

  console.log("jQuery Ready")
  $(".animated_button_container a").click(function (e) {
    e.preventDefault();
    $(this).fadeOut();
    $(this).parent().fadeOut();

    $(this).closest(".animation_container").toggleClass("active");
    $(this)
      .closest(".animation_container")
      .children(".animation_block_price")
      .toggleClass("active");
  });

  if (screen.width < 500) {
    $("#startsida_toppvideo").attr(
      "src",
      "/assets/startsida/BE_Hero_mobil.mp4"
    );

    $("#woman_in_taxi").attr("src", "/assets/startsida/Mobil_Taxikvinna.mp4");

    $("#baby_video").attr("src", "/assets/startsida/Mobil_Baby.mp4");

    $("#waitress_dinner").attr("src", "/assets/startsida/Mobil_Diner.mp4");
  }
  if (screen.width == 768) {
    $("#baby_video_ipad").att("src", "/assets/startsida/700x1000_Baby.mp4");
    $("#woman_in_taxi").att("src", "/assets/startsida/700x1000_Taxikvinna.mp4");
  }

  $(function () {
    setTimeout(function () {
      $(".form_container").fadeIn();
    }, 5000);
  });

  $(".form_container_btn").on("click", function () {
    $(this).parent().fadeOut();
  });

  $(".peter_call_you").on("click", function () {
    $(".contact_popup_form").fadeIn();
    $(".contact_popup_form_close").fadeIn();
  });

  $(".contact_popup_form_close").on("click", function (e) {
    e.preventDefault();
    $(".contact_popup_form").fadeOut();
    $(".contact_popup").hide();
    $(".open_contact_popup").show();
  });

  // $(document).scroll(function () {
  //   if (window.innerWidth < 500) {
  //     var y = $(this).scrollTop();
  //     if (y > 800) {
  //       $(".form_container").fadeIn();
  //     } else {
  //       $(".form_container").fadeOut();
  //     }
  //   }
  // });

  $(".close_contact_popup").on("click", function () {
    $(".contact_popup").hide();
    $(this).closest(".video_button").children(".open_contact_popup").fadeIn();
  });

  $(".open_contact_popup").on("click", function () {
    $(this)
      .closest(".video_button")
      .children(".contact_popup")
      .fadeIn()
      .children(".close_contact_popup")
      .addClass("active");

    $(this).closest("#open_contact_popup").hide();
  });

  $("#open_contact_popup_2").on("click", function () {
    $("#contact_popup_2").fadeIn();
  });

  $(".contact_popup_form_container .submit_btn").click(function (e) {
    e.preventDefault();
    let name = $("#contact_popup_form_name").val();
    let email = $("#contact_popup_form_email").val();
    let phone = $("#contact_popup_form_phone").val();
    let nameFilled = true;
    let emailFilled = true;
    let phoneFilled = true;

    if (name.length <= 0) {
      $(".name_error").addClass("active");
      nameFilled = false;
    } else {
      $(".name_error").removeClass("active");
    }

    if (email.length <= 0) {
      $(".email_error").addClass("active");
      emailedFilled = false;
    } else {
      $(".email_error").removeClass("active");
    }

    if (phone.length <= 0) {
      $(".phone_error").addClass("active");
      phoneFilled = false;
    } else {
      $(".phone_error").removeClass("active");
    }

    if (nameFilled && emailFilled && phoneFilled) {
      sendEmail();
    }
  });

  function sendEmail() {
    var e = {
      from_name: document.getElementById("contact_popup_form_name").value,
      email: document.getElementById("contact_popup_form_email").value,
      phone: document.getElementById("contact_popup_form_phone").value,
    };
    $(".contact_popup_form_container .submit_btn").fadeOut();
    $(".contact_popup_form_loading_message").fadeIn();
    $(".email-succes").addClass("active"),
      $("#loading").removeClass("active"),
      emailjs.send("service_dkzqhcn", "template_34ibpos", e).then(function (e) {
        console.log("succes!" + e.status);
        $(".contact_popup_form_loading_message").fadeOut();
        $(".contact_popup").fadeOut();
        $(".open_contact_popup").show();
        $(".contact_popup_form_success_message").addClass("active");

        setTimeout(function () {
          $(".contact_popup_form").fadeOut("slow");

          $("#contact_popup_form_name").val("");
          $("#contact_popup_form_email").val("");
          $("#contact_popup_form_phone").val("");
          $(".contact_popup_form_success_message").removeClass("active");
          $("form .contact_popup_form_inner .submit_btn").fadeIn();
        }, 2000);
      });
  }

  var videoElements = document.querySelectorAll(".video-element");
  var observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        var video = entry.target;
        video.src = video.dataset.src;
        observer.unobserve(video);
      }
    });
  });

  videoElements.forEach((video) => {
    observer.observe(video);
  });

  if ($(window).width() < 1200) {
    var maxLength = 250; // set maximum length of text to 100 characters
    var $p = $(".image-card .card-inner p:not(.news-date-3)"); // select the p elements
    $p.each(function () {
      var text = $(this).text();
      if (text.length > maxLength) {
        var truncatedText = text.substr(0, maxLength);
        var lastSpaceIndex = truncatedText.lastIndexOf(" ");
        if (lastSpaceIndex !== -1) {
          truncatedText = truncatedText.substr(0, lastSpaceIndex);
        }
        $(this).text(truncatedText + "...");
      }
    });
  } else {
    $(".image-card .card-inner p").css("white-space", "normal"); // make text fully visible
  }
});